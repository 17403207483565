import React from 'react';

const Terminal = () => {
  return (
    <section id="terminal" className="py-12 bg-background text-bone-white hidden md:block">
      <div className="container mx-auto px-4 lg:px-8 text-left">
        <div className="border-b border-gray-700 mb-2"></div>
        <div className="flex flex-wrap space-x-4 text-gray-400">
          <span className="hover:text-white">Problems</span>
          <span className="hover:text-white">Output</span>
          <span className="hover:text-white">Debug Console</span>
          <span className="text-white border-b-2 border-blue-400">Terminal</span>
          <span className="hover:text-white">Ports</span>
          <span className="hover:text-white">Comments</span>
        </div>
        <div className="p-4 overflow-auto">
          <div className="font-mono text-blue-400 break-all">
            <p>PS C:\Users\znobunaga\personal-website&gt; git add .</p>
            <p>PS C:\Users\znobunaga\personal-website&gt; git commit -m "Hi! Thanks for checking out my website!"</p>
            <p>[main 1234567] Hi! Thanks for checking out my website!</p>
            <p>PS C:\Users\znobunaga\personal-website&gt; git push origin main</p>
            <p>To github.com:znobunaga\personal-website.git</p>
            <p>   1214537..8a2b3def  main -&gt; main</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Terminal;
