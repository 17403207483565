import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Toolbar from './components/Toolbar';
import Projects from './components/Projects';
import Work from './components/Work';
import Description from './components/Description';
import Contact from './components/Contact';
import Terminal from './components/Terminal';
import NotFound from './components/NotFound';

const Home = () => (
  <div>
    <Description />
    <Projects />
    <Work />
    <Contact />
    <Terminal />
  </div>
);

function App() {
  return (
    <Router>
      <div className="flex flex-col md:flex-row">
        <NavBar />
        <div className="flex-grow md:ml-48 pt-20">
          <Toolbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<Description />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/work" element={<Work />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
