import React from 'react';

const Toolbar = () => {
  return (
    <div className="absolute top-4 left-12 md:left-56 bg-background text-bone-white w-full md:w-auto md:flex p-4 md:p-0 z-50 hidden md:block"> {/* Adjusted to absolute */}
      <a href="#about" className="toolbar-item block md:inline-block bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
        About.js
      </a>
      <a href="#projects" className="toolbar-item block md:inline-block bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
        Projects.js
      </a>
      <a href="#work" className="toolbar-item block md:inline-block bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
        Work.js
      </a>
      <a href="#contact" className="toolbar-item block md:inline-block bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
        Contact.js
      </a>
    </div>
  );
};

export default Toolbar;
