import React from 'react';

const Work = () => {
  return (
    <section id="work" className="py-12 bg-background text-bone-white md:pl-8 pl-2">
      <div className="container mx-auto px-4 lg:px-8 text-left">
        <h2 className="text-4xl font-bold mb-6">Work Experience</h2>
        <div className="mb-6">
          <h3 className="text-xl font-semibold">Software and IT Intern at Kaiser Permanente</h3>
          <div className="text-sm text-gray-400 mb-2">June 2024 - September 2024</div>
          <div className="code-tag" data-before="<div>" style={{ fontSize: '14px', color: 'blue' }}></div>
          <div className="text-description max-w-2xl mb-2">
          Improved UI for internal tools and software, managed data transfers, and provided tech support and training.
          </div>
          <div className="code-tag" data-after="</div>" style={{ fontSize: '14px', color: 'blue' }}></div>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold">Information Systems Intern at Kaiser Permanente</h3>
          <div className="text-sm text-gray-400 mb-2">June 2023 - September 2023</div>
          <div className="code-tag" data-before="<div>" style={{ fontSize: '14px', color: 'blue' }}></div>
          <div className="text-description max-w-2xl mb-2">
          Aligned technology with business goals, collaborated on data transfers, and contributed to front-end development.
          </div>
          <div className="code-tag" data-after="</div>" style={{ fontSize: '14px', color: 'blue' }}></div>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold">Sales Intern at Aflac</h3>
          <div className="text-sm text-gray-400 mb-2">January 2023 - June 2023</div>
          <div className="code-tag" data-before="<div>" style={{ fontSize: '14px', color: 'blue' }}></div>
          <div className="text-description max-w-2xl mb-2">
          Conducted needs analyses, identified employer values, and overcame sales objections through sales presentations.
          </div>
          <div className="code-tag" data-after="</div>" style={{ fontSize: '14px', color: 'blue' }}></div>
        </div>
        <div className="mb-6">
          <h3 className="text-xl font-semibold">Public Speaking Curriculum Leader at YAPA KIDS</h3>
          <div className="text-sm text-gray-400 mb-2">June 2020 - January 2022</div>
          <div className="code-tag" data-before="<div>" style={{ fontSize: '14px', color: 'blue' }}></div>
          <div className="text-description max-w-2xl mb-2">
          Led a team to create lesson plans and taught Public Speaking classes, ensuring equal learning opportunities for all students.
          </div>
          <div className="code-tag" data-after="</div>" style={{ fontSize: '14px', color: 'blue' }}></div>
        </div>
      </div>
    </section>
  );
};

export default Work;
