import React from 'react';
import { FaLinkedin, FaGithub, FaEnvelope, FaFileAlt } from 'react-icons/fa';

const NavBar = () => {
  return (
    <nav className="bg-gradient-to-r from-black via-gray-900 to-gray-800 text-bone-white w-48 h-screen fixed top-0 left-0 py-6 hidden md:block">
      <div className="container mx-auto text-left px-8">
        <h1 className="text-2xl font-bold mb-4"> {/* Reduced margin-bottom */}
          <a href="/" className="text-white hover:underline">Home</a>
        </h1>
        <ul className="space-y-3">
          <li>
            <a href="#about" className="text-blue-400 hover:underline font-bold text-lg">
              About
            </a>
          </li>
          <li>
            <a href="#projects" className="text-blue-400 hover:underline font-bold text-lg">
              Projects
            </a>
          </li>
          <li>
            <a href="#work" className="text-blue-400 hover:underline font-bold text-lg">
              Work
            </a>
          </li>
          <li>
            <a href="#contact" className="text-blue-400 hover:underline font-bold text-lg">
              Contact
            </a>
          </li>
        </ul>
        <div className="mt-10"> {/* Reduced margin-top */}
          <h2 className="text-2xl text-white font-semibold mb-4">Find Me</h2>
          <ul className="space-y-4">
            <li>
              <a href="https://www.linkedin.com/in/zacharynobunaga/" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-400 hover:underline font-bold text-lg">
                <FaLinkedin size={16} />
                <span className="ml-2">LinkedIn</span>
              </a>
            </li>
            <li>
              <a href="https://github.com/znobunaga" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-400 hover:underline font-bold text-lg">
                <FaGithub size={16} />
                <span className="ml-2">GitHub</span>
              </a>
            </li>
            <li>
              <a href="mailto:znobunaga14@gmail.com" className="flex items-center text-blue-400 hover:underline font-bold text-lg">
                <FaEnvelope size={16} />
                <span className="ml-2">Email</span>
              </a>
            </li>
            <li>
              <a href="/Zachary_Nobunaga_Resume.pdf" target="_blank" rel="noopener noreferrer" className="flex items-center text-blue-400 hover:underline font-bold text-lg">
                <FaFileAlt size={16} />
                <span className="ml-2">Resume</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
