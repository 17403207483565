import React from 'react';
import { FaLinkedin, FaGithub, FaEnvelope, FaFileAlt } from 'react-icons/fa';

const Contact = () => {
  return (
    <section id="contact" className="py-12 bg-background text-bone-white md:pl-8 pl-2 contact-container"> {/* Adjusted padding */}
      <div className="container mx-auto px-4 lg:px-8 text-left">
        <h2 className="text-4xl font-bold mb-5">Contact Me</h2>
        <div className="flex flex-wrap space-x-4 items-center">
          <a href="https://www.linkedin.com/in/zacharynobunaga/" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center space-x-2 bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
            <FaLinkedin size={20} />
          </a>
          <a href="https://github.com/znobunaga" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center space-x-2 bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
            <FaGithub size={20} />
          </a>
          <a href="mailto:znobunaga14@gmail.com" className="flex items-center justify-center space-x-2 bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
            <FaEnvelope size={20} />
          </a>
          <a href="/Zachary_Nobunaga_Resume.pdf" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center space-x-2 bg-gray-800 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded">
            <FaFileAlt size={20} />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Contact;
