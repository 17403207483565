import React, { useRef, useState } from 'react';

const Projects = () => {
  const projectContainerRef = useRef(null);
  const [currentProject, setCurrentProject] = useState(0);
  const projects = [
    {
      title: "Recipe Helper",
      description: "Explore a range of recipes and meal ideas to inspire your cooking.",
      github: "https://github.com/znobunaga/recipe-project",
      demo: "https://recipe-project-ashy.vercel.app/",
    },
    {
      title: "AI@UCR Website",
      description: "Partnered with ACM@UCR to build a responsive website for AI@UCR.",
      github: "https://github.com/acm-ucr/aiatucr-website",
      demo: "https://ai-ucr.ucrhighlanders.org/",
    },
    {
      title: "Currency Converter",
      description: "Convert currencies in real-time with live exchange rates.",
      github: "https://github.com/znobunaga/currency-converter-project.git",
      demo: "https://currency-converter-project-two.vercel.app/",
    },
    {
      title: "ToDo List",
      description: "Manage your daily tasks throughout the day.",
      github: "https://github.com/znobunaga/todo-list-project.git",
      demo: "https://todo-list-project-wheat.vercel.app/",
    },
    // Add more projects as needed
  ];

  const handleDotClick = (index) => {
    setCurrentProject(index);
    if (projectContainerRef.current) {
      projectContainerRef.current.scrollTo({
        top: index * 300, // Adjust this value to control the scroll amount
        behavior: 'smooth',
      });
    }
  };

  return (
    <section id="projects" className="py-12 bg-background text-bone-white md:pl-8 pl-2">
      <div className="container mx-auto px-4 lg:px-8 text-left relative">
        <h2 className="text-4xl font-bold">Projects</h2>
        <br />
        <div className="relative flex items-start overflow-hidden" style={{ maxHeight: '600px' }}>
          <div className="projects-container flex flex-col overflow-y-auto space-y-4" ref={projectContainerRef} style={{ scrollSnapType: 'y mandatory' }}>
            {projects.map((project, index) => (
              <div key={index} className="project-box p-5 border border-gray-700 rounded-lg bg-gray-800 flex-shrink-0" style={{ scrollSnapAlign: 'start' }}>
                <h3 className="text-xl font-semibold mb-2">{project.title}</h3>
                <p className="text-sm text-gray-300 mb-2">{project.description}</p>
                <div className="flex space-x-4">
                  <a href={project.github} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600" style={{ fontSize: '12px' }}>
                    GitHub Repository
                  </a>
                  <a href={project.demo} target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:text-blue-600" style={{ fontSize: '12px' }}>
                    Live Demo
                  </a>
                </div>
              </div>
            ))}
          </div>
          <div className="flex flex-col items-center justify-center ml-4 mt-64"> {/* Increased margin-top to move dots further down */}
            {projects.map((_, index) => (
              <button
                key={index}
                onClick={() => handleDotClick(index)}
                className={`h-3 w-3 rounded-full my-2 ${currentProject === index ? 'bg-white' : 'bg-gray-500'}`}
              ></button>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Projects;
