import React from 'react';
import { Link } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gradient-to-r from-black to-gray-900 text-bone-white">
      <h1 className="text-6xl font-bold mb-4 text-blue-400">404 - Page Not Found</h1>
      <p className="text-xl mb-4 text-gray-400">The page you are looking for does not exist.</p>
      <Link to="/" className="bg-blue-800 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:scale-105">
        Go to Home
      </Link>
    </div>
  );
};

export default NotFound;
