import React from 'react';
import Typewriter from 'typewriter-effect';
import { FaExternalLinkAlt } from 'react-icons/fa';

const Description = () => {
  return (
    <section id="about" className="py-12 bg-background text-bone-white md:pl-8 pl-2">
      <div className="container mx-auto px-4 lg:px-8 text-left">
        <h1 className="text-5xl font-bold mb-8">
          <div className="gradient-text" style={{ lineHeight: '1.2' }}> {/* Adjusted line height */}
            <Typewriter
              options={{
                delay: 50,
                cursor: '|',
              }}
              onInit={(typewriter) => {
                typewriter.typeString("Zachary Nobunaga")
                  .callFunction(() => {
                    document.querySelector('.Typewriter__cursor').style.display = 'none';
                  })
                  .start();
              }}
            />
          </div>
        </h1>
        <div className="text-lg max-w-2xl mb-6">
          <div className="code-tag" data-before="<div>" style={{ fontSize: '14px', color: 'blue' }}></div>
          <Typewriter
            options={{
              delay: 32,
              cursor: '|',
            }}
            onInit={(typewriter) => {
              typewriter.typeString("Studying Computer Science and Business at UCR.")
                .pauseFor(500)
                .typeString(" Aside from coding, I love being outside playing sports, traveling, and trying new food.")
                .callFunction(() => {
                  document.querySelector('.Typewriter__cursor').style.display = 'none';
                })
                .start();
            }}
          />
          <div className="code-tag" data-after="</div>" style={{ fontSize: '14px', color: 'blue' }}></div>
        </div>
        <div className="flex space-x-4 mb-4">
          <a href="https://www.linkedin.com/in/zacharynobunaga/" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline flex items-center">
            LinkedIn <FaExternalLinkAlt className="ml-2" />
          </a>
          <a href="https://github.com/znobunaga" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline flex items-center">
            GitHub <FaExternalLinkAlt className="ml-2" />
          </a>
          <a href="/Zachary_Nobunaga_Resume.pdf" target="_blank" rel="noopener noreferrer" className="text-blue-400 hover:underline flex items-center">
            Resume <FaExternalLinkAlt className="ml-2" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default Description;
